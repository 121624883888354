import { render, staticRenderFns } from "./VerticalNavMenuLink.vue?vue&type=template&id=20d9ebd4&scoped=true&"
import script from "./VerticalNavMenuLink.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuLink.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenuLink.vue?vue&type=style&index=0&id=20d9ebd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d9ebd4",
  null
  
)

export default component.exports